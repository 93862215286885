<template>
    <v-card class="modal-create">
        <v-form ref="form" v-model="valid">
            <v-card-title class="d-flex justify-center">
                <span class="text-h5 text-center mb-11">Добавление клиента</span>
            </v-card-title>
            <v-card-text class="modal-create__form">
                <div class="close-dialog" @click="closeDialog">
                    <img :src="require('@/assets/img/common/close-dialog.svg')" />
                </div>
                <v-col
                        cols="12"
                >
                    <v-text-field
                            class="evi-text-field"
                            color="#44D370"
                            v-model="name"
                            placeholder="Имя клиента"
                            :rules="nameRules"
                    >
                        <template v-slot:label>
                            Имя клиента
                            <span class="important">*</span>
                        </template>
                    </v-text-field>
                </v-col>
            </v-card-text>
            <v-card-actions class="modal-create__actions">
                <v-btn class="evi-button-green evi-button-green--fill" @click="create">
                    Добавить клиента
                </v-btn>
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
import {mapActions} from "vuex";

export default {
    name: "CreateClient",
    data: () => ({
        valid: true,
        name: '',
        nameRules: [(v) => !!v || 'Введите имя клиента'],
    }),
    methods: {
        ...mapActions({
            createClient: 'clients/createClient',
        }),
        validate () {
            return !!this.$refs.form.validate();
        },
        create () {
            if (this.validate()) {
                let payload = {
                    name: this.name,
                };

                this.createClient(payload).then(() => {
                    this.$emit('close');
                    this.$emit('updateClients');
                });
            }
        },
        closeDialog () {
            this.$emit('close');
            this.name = '';
            this.$refs.form.resetValidation();
        }
    }
}
</script>

<style scoped>

</style>
