<template>
  <div>
    <div class="analytics__cards">
      <div
          class="analytics__card"
          @click="setIntType(null)"
          :class="isActiveCard(null)"
      >
        <img width="72" :src="require(`@/assets/img/clients/all.svg`)" alt=""/>
        <p>Все</p>
      </div>
      <div
          class="analytics__card"
          v-for="i in integrationTypesFiltered"
          :key="i.value"
          @click="setIntType(i.value)"
          :class="isActiveCard(i.value)"
      >
        <img width="72" :src="require(`@/assets/img/clients/${i.value}.svg`)" alt=""/>
        <p>
          {{ i.name }}
          <v-menu
              top
              offset-y
              open-on-hover
              max-height="156"
              v-if="isExtremist(i.value)"
          >
            <template v-slot:activator="{ on, attrs }">
              <strong v-bind="attrs" v-on="on" class="red--text">*</strong>
            </template>
            <v-list-item class="user-color-status d-flex justify-space-between align-center">
              Социальная сеть признана экстремистской и запрещена на территории Российской Федерации.
            </v-list-item>
          </v-menu>
        </p>
      </div>
    </div>
    <es-table class="clients" :headers="headers" :items="clients" @update="getClientsPages" :filter="filters" sortable
              dateFilter
              download="clients.export" :importData="importData" @downloadData="downloadData" @uploadData="uploadData"
              upload="clients.import" :importFormats="importFormats">
      <div class="clients__actions" v-if="hasRight('clients.manage')">
        <v-btn class="evi-button-green evi-button-green--fill mr-3" @click="createClientModal = true">
          Добавить клиента
        </v-btn>
      </div>
      <v-dialog
          v-model="createClientModal"
          max-width="612px"
      >
        <CreateClient @updateClients="getClients" @close="createClientModal = false"/>
      </v-dialog>
      <template slot="sort-menu">
        <v-list dense class="settings-menu">
          <v-list-item-group>
            <v-list-item>
              <v-list-item-title>
                <v-autocomplete
                    class="evi-autocomplete"
                    color="#44D370"
                    :items="statuses"
                    v-model="filters['filter[clientStatus.id]']"
                    item-text="name"
                    item-value="id"
                    clearable
                    item-color="green"
                    @focus="getClientStatuses"
                >
                  <template v-slot:label>
                    Фильтровать по статусу
                  </template>
                  <template v-slot:append>
                        <span class="evi-autocomplete__arrow">
                            <v-icon>mdi-chevron-down</v-icon>
                        </span>
                  </template>
                  <template v-slot:selection="{item}">
                    <div class="modal-create__color-select">
                      <span class="color" :style="{backgroundColor: `${item.color}`}"/>
                      {{ item.name }}
                    </div>
                  </template>
                  <template v-slot:item="{item}">
                    <div class="modal-create__color-select">
                      <span class="color mt-1" :style="{backgroundColor: `${item.color}`}"/>
                      {{ item.name }}
                    </div>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                    class="evi-autocomplete"
                    color="#44D370"
                    :items="tags"
                    v-model="filters['filter[tags.id]']"
                    item-text="name"
                    item-value="id"
                    clearable
                    item-color="green"
                    @focus="getTags"
                    multiple
                >
                  <template v-slot:label>
                    Фильтровать по тегу
                  </template>
                  <template v-slot:append>
            <span class="evi-autocomplete__arrow">
                <v-icon>mdi-chevron-down</v-icon>
            </span>
                  </template>
                  <template v-slot:selection="{item}">
                    <div class="evi-autocomplete__tag mr-2 mt-2"
                         :style="{backgroundColor: `${item.color}`, color: `${item.text_color}` }">
                      {{ item.name }}
                    </div>
                  </template>
                  <template v-slot:item="{item}">
                    <div class="evi-autocomplete__tag"
                         :style="{backgroundColor: `${item.color}`, color: `${item.text_color}` }">
                      {{ item.name }}
                    </div>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                    class="evi-autocomplete"
                    color="#44D370"
                    v-model="filters['filter[integration.id]']"
                    :items="integrations"
                    item-text="name"
                    item-value="id"
                    :loading="integrationLoading"
                    @focus="loadIntegrations()"
                    :search-input.sync="integrationSearch"
                    @input.native="searchIntegration(integrationSearch)"
                    clearable
                    item-color="green"
                >
                  <template v-slot:label>
                    Фильтровать по интеграции
                  </template>
                  <template v-slot:append-item>
                    <div v-intersect="integrationIntersect"/>
                  </template>
                  <template v-slot:append>
                      <span class="evi-select__arrow">
                          <v-icon>mdi-chevron-down</v-icon>
                      </span>
                  </template>
                  <template v-slot:selection="{item}">
                    <div class="v-select__selection v-select__selection--comma">
                      <img width="20" class="mr-1"
                           :src="require(`@/assets/img/integrations/${item.type}.svg`)" alt=""/>
                      {{ item.name }}
                    </div>
                  </template>
                  <template v-slot:item="{item}">
                    <v-list-item-title>
                      <img width="20" class="mr-1"
                           :src="require(`@/assets/img/integrations/${item.type}.svg`)" alt=""/>
                      {{ item.name }}
                    </v-list-item-title>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                    class="evi-autocomplete"
                    color="#44D370"
                    :items="users"
                    v-model="filters['filter[responsible_user_id]']"
                    item-text="name"
                    item-value="id"
                    hide-details
                    :loading="userLoading"
                    @focus="loadUsers"
                    :search-input.sync="userSearch"
                    @input.native="searchUser(userSearch)"
                    clearable
                    item-color="green"
                >
                  <template v-slot:label>
                    Фильтровать по ответственному
                  </template>
                  <template v-slot:append-item>
                    <div v-intersect="userIntersect"/>
                  </template>
                  <template v-slot:item="{item}">
                    <v-avatar
                        color="#FFFFFF"
                        size="30"
                        class="user-page__avatar mr-3"
                    >
                      <img
                          :src="item.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                          alt=""
                      >
                    </v-avatar>
                    <div class="v-list-item__title">{{ item.name }}</div>
                  </template>
                  <template v-slot:append>
                                    <span class="evi-autocomplete__arrow">
                                        <v-icon>mdi-chevron-down</v-icon>
                                    </span>
                  </template>
                </v-autocomplete>
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </template>
    </es-table>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import CreateClient from "../../components/clients/CreateClient";
import {hasRight} from '@/utils/access/hasRight';
import {IntegrationAutocompleteRequestsMixin} from "@/mixins/autocomplete/IntegrationAutocompleteRequestsMixin";
import {eventBus} from "../../main";
import exportData from '@/utils/files/requestExport';
import {RouteFilterPaginationMixin} from "../../mixins/RouteFilterPaginationMixin";
import {UserAutocompleteRequestsMixin} from "@/mixins/autocomplete/UserAutocompleteRequestsMixin";

export default {
  name: "Clients",
  mixins: [IntegrationAutocompleteRequestsMixin, RouteFilterPaginationMixin, UserAutocompleteRequestsMixin],
  components: {CreateClient},
  data: () => ({
    createClientModal: false,
    filters: {
      'filter[integration.id]': undefined,
      'filter[integration.type]': undefined,
      'filter[clientStatus.id]': undefined,
      'filter[tags.id]': undefined,
      'filter[responsible_user_id]': undefined,
    },
    importData: {
      title: 'Импорт клиентов',
      template: 'clients'
    },
    importFormats: ['xlsx', 'csv'],
    headers: [
      {
        text: 'Клиент',
        value: 'client_name',
      },
      {
        text: 'Дата создания',
        value: 'created_at',
      },
      {
        text: 'Интеграция (откуда пришел)',
        value: 'integration.name',
      },
      {
        text: 'Статус клиента',
        value: 'client_status',
      },
      {
        text: 'Теги',
        value: 'tags',
      },
      {
        text: 'Email',
        value: 'email',
      },
      {
        text: 'Телефон',
        value: 'phone',
      },
      {
        text: 'Последняя сделка',
        value: 'last_deal.name',
      },
      {
        text: 'Статус последней сделки',
        value: 'last_deal.deal_status.name',
      },
      {
        text: 'Исполнитель последней сделки',
        value: 'last_deal.executor.id',
      },
      {
        text: 'Дата первого контакта',
        value: 'first_contact_date',
      },
      {
        text: 'Дата последнего контакта',
        value: 'last_contact_date',
      },
      {
        text: 'Время ответа сотрудником на 1-ое сообщение клиента',
        value: 'first_message_answer_time',
      },
    ],
  }),
  computed: {
    ...mapState("clients", ["clients",]),
    ...mapState("client-statuses", ["statuses",]),
    ...mapState("tags", ["tags",]),
    ...mapState("integrations", ["integrations", "integrationTypes", "extremists"]),
    ...mapState("user", ["profile", "webSocketData"]),
    integrationTypesFiltered() {
      return this.integrationTypes.filter(i => i.showInClients);
    }
  },
  methods: {
    ...mapActions({
      getClientsPages: 'clients/getClientsPages',
      addClient: 'clients/addClient',
      uploadClients: 'clients/uploadClients',
      getClientStatuses: 'client-statuses/getStatusesBy',
      getTags: 'tags/getItemsBy',
    }),
    isExtremist(v) {
      return this.extremists.some(i => i === v);
    },
    downloadData(payload) {
      exportData(this.$store.dispatch, 'clients/downloadClients', {
        export: payload.export,
        date_from: payload.date_from,
        date_to: payload.date_to
      }, `Клиенты`);
    },
    uploadData(payload) {
      this.uploadClients(payload).then(() => {
        this.getClients();
      });
    },
    hasRight: hasRight,
    setIntType(v) {
      eventBus.$emit('cancelPagination');
      this.filters['filter[integration.id]'] = null;
      this.filters['filter[integration.type]'] = v;
    },
    isActiveCard (v) {
      if (
          this.filters['filter[integration.type]'] === v ||
          !v && !this.filters['filter[integration.type]']) {
        return 'active';
      }
    },
    getClients() {
      this.getClientsPages({
        per_page: 10,
        page: 1,
      });
    },
    clientsFromFormSocket() {
      this.$echo.private(`client.${this.profile.id}`).listen('ClientAdded', (payload) => {
        this.addClient(payload.client);
      });
    },
  },
  mounted() {
    this.getTags();
    this.getClientStatuses();
    this.routeFiltersInit(this.filters);
  },
  watch: {
    'webSocketData.key': {
      handler(val) {
        if (val) {
          this.clientsFromFormSocket();
        }
      },
      immediate: true,
    },
    filters: {
      handler (val) {
        this.filterWatcher(val);
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.clients {
  &__actions {
    margin-left: 23px;
  }
}

//.analytics__card {
//  img {
//    padding-bottom: 5px;
//    padding-top: 5px;
//  }
//}
</style>
