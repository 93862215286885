var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"analytics__cards"},[_c('div',{staticClass:"analytics__card",class:_vm.isActiveCard(null),on:{"click":function($event){return _vm.setIntType(null)}}},[_c('img',{attrs:{"width":"72","src":require("@/assets/img/clients/all.svg"),"alt":""}}),_c('p',[_vm._v("Все")])]),_vm._l((_vm.integrationTypesFiltered),function(i){return _c('div',{key:i.value,staticClass:"analytics__card",class:_vm.isActiveCard(i.value),on:{"click":function($event){return _vm.setIntType(i.value)}}},[_c('img',{attrs:{"width":"72","src":require(("@/assets/img/clients/" + (i.value) + ".svg")),"alt":""}}),_c('p',[_vm._v(" "+_vm._s(i.name)+" "),(_vm.isExtremist(i.value))?_c('v-menu',{attrs:{"top":"","offset-y":"","open-on-hover":"","max-height":"156"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('strong',_vm._g(_vm._b({staticClass:"red--text"},'strong',attrs,false),on),[_vm._v("*")])]}}],null,true)},[_c('v-list-item',{staticClass:"user-color-status d-flex justify-space-between align-center"},[_vm._v(" Социальная сеть признана экстремистской и запрещена на территории Российской Федерации. ")])],1):_vm._e()],1)])})],2),_c('es-table',{staticClass:"clients",attrs:{"headers":_vm.headers,"items":_vm.clients,"filter":_vm.filters,"sortable":"","dateFilter":"","download":"clients.export","importData":_vm.importData,"upload":"clients.import","importFormats":_vm.importFormats},on:{"update":_vm.getClientsPages,"downloadData":_vm.downloadData,"uploadData":_vm.uploadData}},[(_vm.hasRight('clients.manage'))?_c('div',{staticClass:"clients__actions"},[_c('v-btn',{staticClass:"evi-button-green evi-button-green--fill mr-3",on:{"click":function($event){_vm.createClientModal = true}}},[_vm._v(" Добавить клиента ")])],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"612px"},model:{value:(_vm.createClientModal),callback:function ($$v) {_vm.createClientModal=$$v},expression:"createClientModal"}},[_c('CreateClient',{on:{"updateClients":_vm.getClients,"close":function($event){_vm.createClientModal = false}}})],1),_c('template',{slot:"sort-menu"},[_c('v-list',{staticClass:"settings-menu",attrs:{"dense":""}},[_c('v-list-item-group',[_c('v-list-item',[_c('v-list-item-title',[_c('v-autocomplete',{staticClass:"evi-autocomplete",attrs:{"color":"#44D370","items":_vm.statuses,"item-text":"name","item-value":"id","clearable":"","item-color":"green"},on:{"focus":_vm.getClientStatuses},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Фильтровать по статусу ")]},proxy:true},{key:"append",fn:function(){return [_c('span',{staticClass:"evi-autocomplete__arrow"},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"modal-create__color-select"},[_c('span',{staticClass:"color",style:({backgroundColor: ("" + (item.color))})}),_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"modal-create__color-select"},[_c('span',{staticClass:"color mt-1",style:({backgroundColor: ("" + (item.color))})}),_vm._v(" "+_vm._s(item.name)+" ")])]}}]),model:{value:(_vm.filters['filter[clientStatus.id]']),callback:function ($$v) {_vm.$set(_vm.filters, 'filter[clientStatus.id]', $$v)},expression:"filters['filter[clientStatus.id]']"}}),_c('v-autocomplete',{staticClass:"evi-autocomplete",attrs:{"color":"#44D370","items":_vm.tags,"item-text":"name","item-value":"id","clearable":"","item-color":"green","multiple":""},on:{"focus":_vm.getTags},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Фильтровать по тегу ")]},proxy:true},{key:"append",fn:function(){return [_c('span',{staticClass:"evi-autocomplete__arrow"},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"evi-autocomplete__tag mr-2 mt-2",style:({backgroundColor: ("" + (item.color)), color: ("" + (item.text_color)) })},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"evi-autocomplete__tag",style:({backgroundColor: ("" + (item.color)), color: ("" + (item.text_color)) })},[_vm._v(" "+_vm._s(item.name)+" ")])]}}]),model:{value:(_vm.filters['filter[tags.id]']),callback:function ($$v) {_vm.$set(_vm.filters, 'filter[tags.id]', $$v)},expression:"filters['filter[tags.id]']"}}),_c('v-autocomplete',{staticClass:"evi-autocomplete",attrs:{"color":"#44D370","items":_vm.integrations,"item-text":"name","item-value":"id","loading":_vm.integrationLoading,"search-input":_vm.integrationSearch,"clearable":"","item-color":"green"},on:{"focus":function($event){return _vm.loadIntegrations()},"update:searchInput":function($event){_vm.integrationSearch=$event},"update:search-input":function($event){_vm.integrationSearch=$event}},nativeOn:{"input":function($event){return _vm.searchIntegration(_vm.integrationSearch)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Фильтровать по интеграции ")]},proxy:true},{key:"append-item",fn:function(){return [_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.integrationIntersect),expression:"integrationIntersect"}]})]},proxy:true},{key:"append",fn:function(){return [_c('span',{staticClass:"evi-select__arrow"},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"v-select__selection v-select__selection--comma"},[_c('img',{staticClass:"mr-1",attrs:{"width":"20","src":require(("@/assets/img/integrations/" + (item.type) + ".svg")),"alt":""}}),_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-title',[_c('img',{staticClass:"mr-1",attrs:{"width":"20","src":require(("@/assets/img/integrations/" + (item.type) + ".svg")),"alt":""}}),_vm._v(" "+_vm._s(item.name)+" ")])]}}]),model:{value:(_vm.filters['filter[integration.id]']),callback:function ($$v) {_vm.$set(_vm.filters, 'filter[integration.id]', $$v)},expression:"filters['filter[integration.id]']"}}),_c('v-autocomplete',{staticClass:"evi-autocomplete",attrs:{"color":"#44D370","items":_vm.users,"item-text":"name","item-value":"id","hide-details":"","loading":_vm.userLoading,"search-input":_vm.userSearch,"clearable":"","item-color":"green"},on:{"focus":_vm.loadUsers,"update:searchInput":function($event){_vm.userSearch=$event},"update:search-input":function($event){_vm.userSearch=$event}},nativeOn:{"input":function($event){return _vm.searchUser(_vm.userSearch)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Фильтровать по ответственному ")]},proxy:true},{key:"append-item",fn:function(){return [_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.userIntersect),expression:"userIntersect"}]})]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"user-page__avatar mr-3",attrs:{"color":"#FFFFFF","size":"30"}},[_c('img',{attrs:{"src":item.avatar_url ||require('@/assets/img/users/profile-avatar.png'),"alt":""}})]),_c('div',{staticClass:"v-list-item__title"},[_vm._v(_vm._s(item.name))])]}},{key:"append",fn:function(){return [_c('span',{staticClass:"evi-autocomplete__arrow"},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]},proxy:true}]),model:{value:(_vm.filters['filter[responsible_user_id]']),callback:function ($$v) {_vm.$set(_vm.filters, 'filter[responsible_user_id]', $$v)},expression:"filters['filter[responsible_user_id]']"}})],1)],1)],1)],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }